import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Grid, Typography, Container } from "@material-ui/core"

class Careers extends React.Component {
  render() {
    return (
      <Layout>
        <div className="LinkedPageBackgroundColor">
          <Container maxWidth="md">
            <SEO title="Careers"></SEO>
            <h1 align="center">Careers</h1>
            <div className="TopQuote" align="center">
              Join us as we advance the financial well-being of many people!
            </div>

            <div className="PageContent">
              Are you up looking for your next great career step that just may
              do a lot of good? Our path ahead will demand relentless focus on
              execution, learning quickly, and adapting as we go without ever
              losing sight of our mission. This will give you the opportunity to
              work on a wide variety of projects and activities over time that
              advance your professional skills
            </div>
            <div className="PageContent">
              We are early and moving fast, so offically we don't have
              "postings." But we are always interested in talking to people that
              are drawn to our mission and would bring a lot. We see this as a
              "techfin" business, where tech is the catalyst for product
              innovation primed now for success. So we seek both knowledgeable
              insiders that are unsatisfied with what financial services
              industry is doing for most people and highly skilled technology
              business outsiders that want a shot at this industry.
            </div>
            <div className="PageContent">
              We are experienced startup hands, product builders, experience
              designers, marketers, and investors. We need people that bring
              more of the same, specifically in product and customer
              development, customer acquistion, performance marketing, business
              dev, and investment strategies across a wide range of asset
              classes. We intend to grow quickly and make our presence felt.
            </div>
            <div className="PageContent">
              What will it be like to work at 99rises? You get to say but we do
              have some beliefs here. First and foremost, we believe the team as
              a whole is what matters the most and so we seek people that
              understand and support this principle even if they do so according
              to their own background, style, stage of career, skills and
              knowledge, and personality. Other traits we value highly including
              the desire and tendency to always be learning, a focus on honing
              your skills in working with others, a commitment to developing
              your professional competencies, and a desire to make the journey
              pleasant for yourself and others.
            </div>
            <div className="PageContent">
              If you are intrigued, send us email at{" "}
              <a href="mailto:careers@99rises.com">careers@99rises.com</a>.
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}
export default Careers
